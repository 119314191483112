.node-label {
    font-size: 20px;
    font-family: 'Nunito', Arial, sans-serif;
    text-align: left;
    font-weight: bold;
    color: black;
}

.port-label {
    color: black  ;
}
