/* src/assets/fonts/Nunito/fonts.css */
@font-face {
    font-family: 'Nunito';
    src: url('./Nunito-VariableFont_wght.ttf') format('truetype');
    font-weight: 400;
}

@font-face {
    font-family: 'Nunito';
    src: url('./Nunito-Italic-VariableFont_wght.ttf') format('truetype');
    font-style: italic;
}
